import { Dot, Box, Icon, Text } from '@bitrise/bitkit';
import { ValidationResult } from '@/models/Validation';

export type ValidationTooltipLineProps = Omit<ValidationResult<string>, 'key'>;

const PasswordTooltipLine = ({ description, isInvalid }: ValidationTooltipLineProps): JSX.Element => (
  <Box display="flex" gap="8" alignItems="center">
    {isInvalid && (
      <Box display="flex" justifyContent="center" alignItems="center" flexBasis="1">
        <Dot size={6} backgroundColor="neutral.80" />
      </Box>
    )}
    {!isInvalid && (
      <Icon width="16px" name="Check" textColor="green.50" flexBasis="16px" flexShrink="0" height="16px" />
    )}
    <Text size="2" textColor={isInvalid ? 'neutral.40' : 'purple.10'}>
      {description}
    </Text>
  </Box>
);

export type ValidationTooltipProps = {
  title: string;
  results: ValidationResult<string>[];
};

const ValidationTooltip = ({ title, results }: ValidationTooltipProps): JSX.Element => {
  return (
    <Box display="flex" flexDirection="column" gap="8" maxWidth={['100%', '300px']}>
      <Text size="2" textColor="purple.10" fontWeight="bold">
        {title}
      </Text>
      {results.map(({ key, description, isInvalid }) => (
        <PasswordTooltipLine key={key} description={description} isInvalid={isInvalid} />
      ))}
    </Box>
  );
};

export default ValidationTooltip;
